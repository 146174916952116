import { Controller } from "@hotwired/stimulus";

class EventsController extends Controller<HTMLFormElement> {
  static targets = ["tags", "campus"];

  declare readonly tagsTarget: HTMLSelectElement;
  declare readonly campusTarget: HTMLSelectElement;

  connect() {
    this.initializeSelectInputs();
  }

  initializeSelectInputs() {
    const url = new URL(window.location.href); 

    for (const input of this.selectInputs) {
      const paramValue = `q[${input.dataset.eventsTarget}_id_eq]`;
      const value = url.searchParams.get(paramValue);

      // We're initializing selectize here so that we can control the event listeners
      $(input).selectize({
        hideSelected: true,
        items: value ? [value] : [],
        onChange: () => this.element.requestSubmit(),
      });
    }
  }

  get selectInputs() {
    return [
      this.tagsTarget,
      this.campusTarget,
    ]
  }
}

export default EventsController;