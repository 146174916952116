import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false

declare global {
  interface Window {
    Stimulus: Application
  }
}

window.Stimulus   = application

// disable turbo globally until we figure out a solution to loading dropzone on turbo:load
Turbo.session.drive = false

export { application }
